import React from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Helmet } from "react-helmet";
import Intro from '../components/Intro/Intro';
import Github from '../components/Github/github.component';
import OSS from '../components/OSS/oss.component';
import SKILLS from '../components/Skills/Skills.component';



class About extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
        <SEO title="vikash chander"/>
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Me</title>
        </Helmet>
         <Intro/>
         <Github/>
         <OSS/>
         <SKILLS/>
      </Layout>
    </React.Fragment>
    )
  }
}

export default About;
