import React,{useState,useEffect} from 'react';
import './oss.styles.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';


const Contribution =()=> {
  const [oss, setOss] = useState(null);
  useEffect(() => {
    console.log('inside Component');
  const apiUrl =`https://api-vikashchander.vercel.app/oss`;
  // fetch(apiUrl)
  // .then((res) => res.json())
  // .then((repos) => console.log(repos.data.Projects)
  //   );
   axios({method: 'get',url:apiUrl}).then(res=> setOss([...res.data.OSSContribution]));
  },[setOss]);
    return (
      <React.Fragment>
        <div id='contribution'>
        <h3 className='ml-5 big-text box-underline oss-header' >
          <FontAwesomeIcon icon={faProjectDiagram} color='black' className='heart mr-2' />
            OSS CONTRIBUTION
          </h3>
        <div className='container-fluid d-flex flex-row flex-wrap justify-content-start mb-5'>
          {
            oss===null? <p>loading ...</p>: 
            oss.map((data,index)=>(
              <div key={index} className="shadow p-3 m-3 bg-white rounded contribution-card">
              <img  src={data.Image} className="card-img-top" height='200' alt="..." />
            </div>
            ))
          }
        </div>
        </div>
      </React.Fragment >
    )
  }

export default Contribution;