import React from "react"
import "./github.styles.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDay} from "@fortawesome/free-solid-svg-icons"
import GitHubCalender from "react-github-calendar"

export default class Github extends React.Component {
  render() {
    return (
      <React.Fragment>
          <h4 className="big-text mx-2 box-underline">
            {" "}
            <FontAwesomeIcon
              icon={faCalendarDay}
              className="heart mx-2 mt-1"
            />{" "}
            My Github Calendar
          </h4>
          <GitHubCalender username="vikashchander" />
      </React.Fragment>
    )
  }
}
