import React from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTerminal } from "@fortawesome/free-solid-svg-icons"
import './Skills.styles.css';

export default class Skills extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div id='skills'>
          <h2 className="mx-5 big-text box-underline skills-header">
            <FontAwesomeIcon icon={faTerminal} className="heart mr-2" />
            Skill Sets
          </h2>
          <div className="d-flex flex-row flex-wrap m-3">
            <img
              src="https://image.flaticon.com/icons/svg/121/121537.svg"
              className="rounded-circle m-2"
              height="100"
              alt="..."
            />
            <img
              src="https://image.flaticon.com/icons/svg/888/888847.svg"
              className="rounded-circle m-2"
              height="100"
              alt="..."
            />
            <img
              src="https://www.flaticon.com/premium-icon/icons/svg/1183/1183672.svg"
              className="rounded-circle mx-2"
              height="100"
              alt="..."
            />
            <img
              src="https://image.flaticon.com/icons/svg/1822/1822899.svg"
              className="rounded-circle mx-2"
              height="100"
              alt="..."
            />
          </div>
          <div className="d-flex flex-row flex-wrap m-3">
            <img
              src="https://cdn0.iconfinder.com/data/icons/flat-round-system/512/java-128.png"
              className="rounded-circle m-2"
              height="100"
              alt="..."
            />
            <img
              src="https://cdn4.iconfinder.com/data/icons/logos-brands-5/24/graphql-128.png"
              className="rounded-circle m-2"
              height="100"
              alt="..."
            />
            <img
              src="https://cdn4.iconfinder.com/data/icons/smashicons-file-types-flat/56/21_-_JS_File_Flat-128.png"
              className="rounded-circle m-2"
              height="100"
              width="100"
              alt="..."
            />
            <img
              src="https://cdn3.iconfinder.com/data/icons/programming-16/512/programming_6_00001-2-128.png"
              className="rounded-circle m-2"
              height="100"
              width="100"
              alt="..."
            />
          </div>
          <div className="d-flex flex-row flex-wrap m-3">
            <img
              src="https://cdn4.iconfinder.com/data/icons/logos-3/456/nodejs-new-pantone-black-128.png"
              className="rounded-circle m-2"
              height="100"
              width="120"
              alt="..."
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
